<template>
  <div class="container">
    <h1>Contact Us</h1>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="name"
            placeholder="Enter your name"
            :rules="nameRules"
            :counter="30"
            label="Name"
            required
          ></v-text-field>
        </v-col>
<!-- 
        <v-col cols="12" md="6">
          <v-text-field
            v-model="email"
            placeholder="Enter your email"
            :rules="emailRules"
            label="E-mail"
            required
            :counter="30"
          ></v-text-field>
        </v-col> -->
      </v-row>
      <v-row>
        <v-textarea
          outlined
          v-model="message"
          placeholder="Type away"
          :rules="messageRules"
          label="Message"
          required
          :counter="2000"
        ></v-textarea>
      </v-row>
      <v-row>
        <v-col
          style="
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding: 0.8em;
          "
        >
          <v-btn
            @click="
              sendMail();
              return false;
            "
            :disabled="!isCompleted"
            >Send Email</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "Contact",
  data: () => ({
    IT_EMAIL: "shakir@insidetravel.ca",
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => v.length <= 30 || `Name must be less than 30 characters`,
    ],
    // emailRules: [
    //   (v) => !!v || "E-mail is required",
    //   (v) =>
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    //       v
    //     ) || "E-mail must be valid",
    //   (v) => v.length <= 30 || `Name must be less than 30 characters`,
    // ],
    message: "",
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => v.length <= 2000 || `Message must be less than 2000 characters`,
    ],
  }),

  computed: {
    isCompleted: function () {
      return this.name && this.message;
    },
  },

  methods: {
    sendMail: function () {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }
      const link =
        `mailto:${this.IT_EMAIL}` +
        "?subject=" +
        encodeURIComponent("Reaching Out to Inside Travel") +
        "&body=" +
        `From: ${encodeURIComponent(this.name)}%0D%0A%0D%0A` +
        encodeURIComponent(this.message);
      window.location.href = link;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 2em;
  overflow-y: auto;
  height: calc(100vh - 24.5rem);
}
.container .v-textarea {
  margin: 0.8em;
}
</style>

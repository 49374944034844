var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('h1', [_vm._v("Contact Us")]), _c('v-form', {
    ref: "form"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "Enter your name",
      "rules": _vm.nameRules,
      "counter": 30,
      "label": "Name",
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('v-row', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "placeholder": "Type away",
      "rules": _vm.messageRules,
      "label": "Message",
      "required": "",
      "counter": 2000
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  })], 1), _c('v-row', [_c('v-col', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "width": "100%",
      "padding": "0.8em"
    }
  }, [_c('v-btn', {
    attrs: {
      "disabled": !_vm.isCompleted
    },
    on: {
      "click": function click($event) {
        _vm.sendMail();

        return false;
      }
    }
  }, [_vm._v("Send Email")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }